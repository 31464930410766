// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  Grid,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  CircularProgress,
  Badge,
  SwipeableDrawer,
} from "@material-ui/core";
import { PostingFull } from "./PostingFull";
import { PostingModel } from "../../../../Models/PostingModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import {
  LanguageHelper,
  getLanguage,
  getDateFormatAccordingToLanguage,
} from "../../../../Helpers/LanguageHelper";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { RequestModel } from "../../../../Models/RequestModel";
import { RequestHelper } from "../../../../Helpers/RequestHelper";
import { getDateObjectFromDateString } from "../../../../Helpers/StringExtractorHelper";
import qs from "qs";
import { PostingHelper } from "../../../../Helpers/PostingHelper";
import { PageLoader } from "../../../Parts/General/PageLoader";
import { MessageNetworksModel } from "../../../../Models/MessageNetworksModel";
import { ChatHelper } from "../../../../Helpers/ChatHelper";
import { Chat } from "../Shared/Chat";
import { FreelancerFeedback } from "./FreelancerFeedback";
import moment from "moment";
import { RatingModel } from "../../../../Models/RatingModel";
import { PastPostingModel } from "../../../../Models/PastPostingModel";
import { FirstJobConfirmation } from "./FirstJobConfirmation";
import { UserHelper } from "../../../../Helpers/UserHelper";
import {
  nowAsFormatedString,
  getNowAsDbString,
} from "../../../../Helpers/DateTimeHelper";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { shouldQuarantineForLateCancellation } from "../../../../Modules/Cancelation";

interface IProps extends RouteComponentProps { }

interface IState {
  posting: PostingModel | null;
  months: string[];
  request: RequestModel | undefined;
  rating: RatingModel | null;
  openWithDrawDialog: boolean;
  openCancelDialog: boolean;
  shouldBeWarnedOfLateCancelationQuarantine: boolean;
  buttonsDisabled: boolean;
  isLoading: boolean;
  unreadMessages: number;
  messageNetworkId: string;
  chatDrawerOpen: boolean;
  jobTitle: string;
  firstJob: boolean;
  isConfirmingFirstJob: boolean;
}

export class Shift extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  languageHelper = new LanguageHelper();
  requestHelper = new RequestHelper();
  postingHelper = new PostingHelper();
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      posting: null,
      months: [],
      request: undefined,
      rating: null,
      openWithDrawDialog: false,
      openCancelDialog: false,
      shouldBeWarnedOfLateCancelationQuarantine: false,
      buttonsDisabled: false,
      isLoading: false,
      unreadMessages: 0,
      messageNetworkId: "",
      chatDrawerOpen: false,
      jobTitle: "",
      firstJob: false,
      isConfirmingFirstJob: false,
    };

    this.preparePage = this.preparePage.bind(this);
    this.jobAddress = this.jobAddress.bind(this);
    this.showDate = this.showDate.bind(this);
    this.withDrawOrCancelApplication =
      this.withDrawOrCancelApplication.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.backButtonHit = this.backButtonHit.bind(this);
    this.showCancellationMessage = this.showCancellationMessage.bind(this);
    this.ratingChanged = this.ratingChanged.bind(this);
    this.confirmFirstJob = this.confirmFirstJob.bind(this);
  }

  async componentDidMount() {
    await this.preparePage();
  }

  async preparePage() {
    await this.setState({ isLoading: true });
    let user = getCurrentUser();
    if (!user || !user.Freelancer) return;

    let posting: PostingModel | null = null;
    let rating: RatingModel | null = null;

    let request: RequestModel | null = null;
    if (this.props.location.state) {
      //@ts-ignore
      request = this.props.location.state.activeRequest;
    } else if (posting != null) {
      let allRequests: RequestModel[] =
        await this.requestHelper.getAllRequestsForFreelancer();
      request = allRequests.find((x) => x.PostingId === posting!.Id) || null;
    }

    if (this.props.location.state) {
      //@ts-ignore
      posting = this.props.location.state.activePosting;
      if (request && posting) {
        posting.SelfPaidBreak = request.SelfPaidBreak;
      }
      //@ts-ignore
      rating =
        (
          this.props.location.state.historyObjects as PastPostingModel
        ).Ratings.find(
          (x) => x != null && request != null && x.RequestId === request.Id
        ) || null;
    }

    let id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;

    if (posting == null && id) {
      posting = await this.postingHelper.getPosting(id);
    }

    if (!posting) {
      return this.props.history.push("/freelancer/shifts");
    }

    if (!posting.StartAtLocalObject || !posting.EndAtLocalObject) {
      posting.StartAtLocalObject = getDateObjectFromDateString(
        posting.StartAtLocal
      );
      posting.EndAtLocalObject = getDateObjectFromDateString(
        posting.EndAtLocal
      );
    }

    if (rating == null && request) {
      rating = await this.postingHelper.getRating(request.Id);
    }

    if (!posting || !request) {
      return this.props.history.push("/freelancer/shifts");
    }

    let months = await this.languageHelper.getMonthsByLanguage(true);

    let days: string[] = [];
    for (let i = 8; i <= 14; i++) {
      days.push(getLanguage(i));
    }

    let messageNetwork: MessageNetworksModel[] =
      await new ChatHelper().getAllMessageNetworksAndMessages();
    if (posting && messageNetwork && messageNetwork.length > 0) {
      messageNetwork = messageNetwork.filter(
        (x) => x.PostingId === posting!.Id
      );
      if (messageNetwork && messageNetwork.length > 0) {
        await this.setState({ messageNetworkId: messageNetwork[0].Id });
      }
    }

    for (const u of messageNetwork) {
      for (const j of u.Logins) {
        if (j.Id === user.Id) {
          if (
            j.MessageNetworkMembers &&
            ((u.LastMessageDate &&
              j.MessageNetworkMembers.LastSeenDate &&
              j.MessageNetworkMembers.LastSeenDate < u.LastMessageDate) ||
              (u.LastMessageDate &&
                j.MessageNetworkMembers.LastSeenDate == null))
          ) {
            await this.setState({ unreadMessages: 1 });
          }
        }
      }
    }

    let skills = this.storageHelper.getSkillsFromLocal();
    let jobTitle = "jobtitle";
    if (skills != null && posting != null) {
      let skill = skills.find((x) => x.Id === posting!.SkillId);
      jobTitle = getLanguage(
        skill!.getLanguageCode(posting.AmountNeeded, true)
      );
    }

    let firstJob =
      user.Freelancer.LastConfirmedSmsNotification == null &&
      request.RequestStatus === 3;

    this.setState({
      months: months!,
      posting,
      rating,
      request,
      jobTitle,
      isLoading: false,
      firstJob,
    });
  }

  showDate() {
    if (this.state.posting && this.state.posting.StartAtLocalObject) {
      return (
        <div className="inline-block">
          {this.state.posting && this.state.posting.StartAtLocal
            ? getDateFormatAccordingToLanguage(
              this.state.posting.StartAtLocal,
              false,
              true,
              this.state.posting.EndAtLocal
            )
            : ""}{" "}
          |&nbsp;
          {this.state.posting.AmountNeeded}
          &nbsp;
          {this.state.jobTitle}
        </div>
      );
    }
    return;
  }

  jobAddress() {
    if (this.state.posting && this.state.posting.StartAtLocalObject) {
      return (
        <div>
          <i className="fas fa-map-marker-alt fa-fw" />{" "}
          {this.state.posting.AddressLine1} {this.state.posting.AddressLine2},{" "}
          {this.state.posting.ZipCode} {this.state.posting.City}
        </div>
      );
    }
    return;
  }

  async withDrawOrCancelApplication(call: "withdraw" | "cancel") {
    let requestId = "";
    if (this.state.request) {
      requestId = this.state.request.Id;

      let success = await this.requestHelper.cancelRequest(requestId);

      if (success) {
        if (call === "withdraw") {
          SnackbarManager.Instance.addSuccess(
            getLanguage(345, "Shift withdrawn")
          );
        } else {
          SnackbarManager.Instance.addSuccess(
            getLanguage(347, "Shift cancelled")
          );
          this.storageHelper.sendEvent("trackEvent", {
            event: "CancelFreelanceJob",
            data: "Chabber",
          });
        }
        return this.props.history.push("/freelancer/shifts");
      }
      if (call === "withdraw") {
        SnackbarManager.Instance.addError(
          getLanguage(346, "Couldn’t withdraw shift")
        );
      } else {
        SnackbarManager.Instance.addError(
          getLanguage(348, "Couldn’t cancel shift")
        );
      }
    }
  }

  async openDialog() {
    if (this.state.request) {
      if (this.state.request.RequestStatus === 3) {
        const parsedWorkStart = moment(this.state.request.WorkDateTime);
        return await this.setState({
          openCancelDialog: true,
          shouldBeWarnedOfLateCancelationQuarantine:
            shouldQuarantineForLateCancellation(parsedWorkStart),
        });
      } else if (this.state.request.RequestStatus === 0) {
        return await this.setState({ openWithDrawDialog: true });
      }
    }
    return;
  }

  async closeDialog() {
    await this.setState({ openCancelDialog: false, openWithDrawDialog: false });
  }

  topDialog() {
    if (this.state.request && this.state.request.RequestStatus === 3) {
      let cancelButton = null;

      if (
        this.state.posting &&
        moment(this.state.posting.EndAtLocal).isAfter(moment())
      ) {
        cancelButton = (
          <Button
            className="inputGroup_item danger"
            variant="text"
            onClick={this.openDialog}
          >
            <span className="btn-icon">
              <i className="fas fa-ban" />
            </span>
            {getLanguage(253, "Cancel shift")}
          </Button>
        );
      }

      return (
        <div className="inputGroup">
          <Badge
            color="error"
            variant="dot"
            badgeContent={this.state.unreadMessages}
            className="inputGroup_item badge"
            classes={{ badge: "badgeElement", dot: "badgeDot" }}
          >
            <Button
              className="white"
              variant="contained"
              onClick={() => this.toggleChat(true)}
            >
              <span className="btn-icon">
                <i className="fas fa-comment" />
              </span>
              {getLanguage(212, "Chat")}
            </Button>
          </Badge>
          {/* <Button className="white inputGroup_item" variant="contained">
            <span className="btn-icon">
              <i className="fas fa-user-friends" />
            </span>
            {getLanguage(254, "Team")}
          </Button> */}

          {cancelButton}
        </div>
      );
    } else if (this.state.request && this.state.request.RequestStatus === 0) {
      return (
        <div>
          <Button
            className="white inputGroup_item"
            variant="contained"
            onClick={this.openDialog}
          >
            <span className="btn-icon">
              <i className="fas fa-redo-alt fa-flip-horizontal" />
            </span>
            {getLanguage(255, "Withdraw")}
          </Button>
        </div>
      );
    } else {
      return;
    }
  }

  toggleChat = (chatDrawerOpen: boolean) => {
    this.storageHelper.sendEvent("toggleIntercom", chatDrawerOpen);
    this.setState({
      chatDrawerOpen,
      unreadMessages: 0,
    });
  };

  backButtonHit() {
    this.props.history.push("/freelancer/shifts");
  }

  showCancellationMessage() {
    if (this.state.request && this.state.request.FreelancerProfile) {
      let cancellationProcentage =
        this.state.request.FreelancerProfile.CancellationPercentageWithOneExtraCancel.toString();

      if (this.state.shouldBeWarnedOfLateCancelationQuarantine) {
        return (
          <div>
            {getLanguage(
              905,
              "You're trying to cancel a shift less than 4 hours prior to the start. This means your profile will be quarantined if you proceed. Contact support if you have questions."
            )}
          </div>
        );
      }
      let withDrawMessage = getLanguage(
        739,
        "A cancellation will be visible on your profile. Your cancel percentage will be {{cancelpercentage}}. This will affect your chances of getting confirmed for shifts in the future."
      ).replace(
        "{{cancelpercentage}}",
        "<b>" + cancellationProcentage + "%</b>"
      );

      return <div dangerouslySetInnerHTML={{ __html: withDrawMessage }} />;
    }
    return;
  }

  renderFreelancerFeedback() {
    if (!this.state.posting) return;

    if (
      getNowAsDbString() > this.state.posting.EndAtLocal &&
      this.state.request
    ) {
      return (
        <FreelancerFeedback
          posting={this.state.posting}
          request={this.state.request}
          rating={this.state.rating}
          ratingChanged={this.ratingChanged}
        />
      );
    }
    return null;
  }

  async confirmFirstJob() {
    await this.setState({ isConfirmingFirstJob: true });
    let response = await this.userHelper.confirmFirstJob();
    if (response) {
      let user = getCurrentUser();
      if (user && user.Freelancer) {
        // @ts-ignore
        user.Freelancer.LastConfirmedSmsNotification = nowAsFormatedString();
        this.storageHelper.setCurrentUser(JSON.stringify(user));
      }

      this.storageHelper.sendEvent("trackEvent", {
        event: "ConfirmedFirstJob",
        data: "Freelancer",
      });
      this.setState({ isConfirmingFirstJob: false, firstJob: false });
    }
    this.setState({ isConfirmingFirstJob: false });
  }

  async ratingChanged(value: number) {
    let rating = this.state.rating;

    if (rating != null) {
      rating.RatingValue = value;
      rating.RatingValue1 = value;
      rating.RatingValue2 = value;
      rating.RatingValue3 = value;
    } else {
      rating = new RatingModel({
        RatingValue: value,
        RatingValue1: value,
        RatingValue2: value,
        RatingValue3: value,
      });
      await this.setState({ rating });
    }
  }

  render() {
    let postingFull;
    if (this.state.posting) {
      postingFull = (
        <PostingFull
          visiting={false}
          location={this.props.location}
          history={this.props.history}
          match={this.props.match}
          posting={this.state.posting}
          request={this.state.request}
          viewState={"freelancer-searched"}
        />
      );
    }

    return (
      <div>
        {this.state.isLoading ? <PageLoader releaseNavbar={true} /> : null}
        <div className="margin-v-24">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1 className="margin-bottom-0">
                <span className="margin-right-8 cursorPointer inline-block">
                  <i
                    className="fas fa-arrow-left brand fa-sm"
                    onClick={this.backButtonHit}
                  ></i>
                </span>
                {this.showDate()}
              </h1>
              <div className="margin-0">{this.jobAddress()}</div>
            </Grid>
            <Grid item>
              <div className="inputGroup">{this.topDialog()}</div>
            </Grid>
          </Grid>
        </div>
        {this.renderFreelancerFeedback()}
        <FirstJobConfirmation
          hidden={this.state.firstJob === false}
          confirmFirstJob={this.confirmFirstJob}
          isConfirming={this.state.isConfirmingFirstJob}
        />

        {postingFull}
        {/* WITHDRAW DIALOG */}
        <Dialog
          className="cancelDialog dialog"
          open={this.state.openWithDrawDialog}
          aria-labelledby="delete-dialog"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {getLanguage(256, "Withdraw application?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {getLanguage(
                257,
                "Withdrawing your application will not affect your profile. If you change your mind you can always re-apply again later."
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={this.closeDialog}>
              {getLanguage(242, "Cancel")}
            </Button>
            <Button
              disabled={this.state.buttonsDisabled}
              onClick={() => this.withDrawOrCancelApplication("withdraw")}
            >
              {getLanguage(258, "Yes, withdraw")}
              {this.state.buttonsDisabled && (
                <CircularProgress size={20} className="buttonLoader" />
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* CANCEL DIALOG */}
        <Dialog
          className="cancelDialog dialog"
          open={this.state.openCancelDialog}
          aria-labelledby="delete-dialog"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {getLanguage(259, "Cancel your shift?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.showCancellationMessage()}
            </DialogContentText>
            <TextField
              type="text"
              label={getLanguage(
                262,
                "Tell the company why you are cancelling"
              )}
              multiline
              rows="3"
              variant="outlined"
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={this.closeDialog}>
              {getLanguage(218, "Back")}
            </Button>
            <Button
              disabled={this.state.buttonsDisabled}
              className="danger"
              onClick={() => this.withDrawOrCancelApplication("cancel")}
            >
              {getLanguage(263, "Yes, cancel shift")}
              {this.state.buttonsDisabled && (
                <CircularProgress size={20} className="buttonLoader" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <SwipeableDrawer
          anchor="right"
          open={this.state.chatDrawerOpen}
          onOpen={() => this.toggleChat(true)}
          onClose={() => this.toggleChat(false)}
          variant="temporary"
          classes={{ paper: "chatDrawer" }}
        >
          <Chat messageNetworkId={this.state.messageNetworkId}></Chat>
        </SwipeableDrawer>
      </div>
    );
  }
}
